@import '../../theme-bootstrap';

// .cr21-refresh class is added programmatically to site_header_formatter_v1
// First section here we override styles for search within nav.
// Search result style overrides are lower in this file.

.cr21-refresh {
  .site-header-formatter-search-input-field {
    box-shadow: unset;
    background: $color-cta-grey--opacity-20;
    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    @include swap_direction(padding, 10px 20px 8px 34px);
    line-height: 1;
    border-radius: 20px;
    &::placeholder {
      @include body-text--bold;
      color: $color--white;
      opacity: 0.5;
      font-size: 12px;
    }
  }
  .site-header-formatter-search-icon {
    #{$ldirection}: 10px;
    fill: $color--white;
    top: 16px;
    height: 16px;
    width: 16px;
  }
  .site-header-formatter-search-icon-wrapper {
    width: calc(100% - 125px);
    padding: 0;
    @include swap_direction(margin, 12px 15px 18px 20px);
  }
  // mainly the utility block
  .header-search {
    @include breakpoint($bp--largest-up) {
      background-color: transparent;
    }
    .header-search__icon {
      color: $color--black;
    }
    .header-search__form {
      padding-top: 12px;
      @include breakpoint($bp--medium-up) {
        padding-top: 5px;
      }
      @include breakpoint($bp--medium-portrait) {
        padding-top: 10px;
      }
      @include breakpoint($bp--xxlarge-up) {
        padding-top: 5px;
      }
      .header-search-icon {
        fill: $color--black;
        height: 30px;
        margin-#{$ldirection}: 13px;
        top: 0;
        @include breakpoint($bp--largest-up) {
          top: 10%;
          #{$ldirection}: 2px;
          width: 17px;
        }
      }
    }

    &.header-search-display-input {
      .header-search__field {
        @include swap_direction(padding, 10px 15px 8px 37px);
        @include body-text--small-bold;
        background-color: $color--black--opacity-10;
        border: none;
        border-radius: 37px;
        line-height: 1;
        font-weight: normal;
        text-transform: uppercase;
        color: $color--black;
        width: 70%;
        z-index: 2;
        position: relative;
        @include breakpoint($bp--largest-up) {
          @include swap_direction(padding, 20px 15px 17px 37px);
          margin: 0;
          box-shadow: none;
          width: 136px;
        }
        &:focus {
          border: none;
          &:placeholder {
            opacity: 0.5;
          }
        }
        &::placeholder {
          @include body-text--small-bold;
          height: 37px;
          line-height: 37px;
          text-transform: uppercase;
          color: $color-text-grey;
        }
      }
      .header-search__icon {
        top: auto;
        margin: 0;
        line-height: 51px;
        height: 37px;
        #{$rdirection}: auto;
        #{$ldirection}: 14px;
        color: $color--black;
        z-index: 501;
        @include breakpoint($bp--medium-up) {
          padding: 0;
          #{$ldirection}: auto;
          margin-top: -7px;
        }
        @include breakpoint($bp--largest-up) {
          padding: 0;
          #{$ldirection}: 14px;
        }
        svg {
          fill: $color--black;
          .gnav-transparent--light:not(.site-header-formatter--sticky) & {
            fill: $color--white;
          }
          .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
            fill: $color--black;
          }
        }
        &.pc-search__icon {
          @include breakpoint($bp--medium-portrait) {
            display: none;
          }
        }
        i {
          background: url('#{$base-theme-path}svg-icons/src/icon--search.svg');
          background-size: contain;
          background-repeat: no-repeat;
          width: 17px;
          height: 17px;
          margin-top: 13px;
          @include breakpoint($bp--large-up) {
            margin-top: 20px;
          }
          @include breakpoint($bp--largest-up) {
            margin-top: 0;
          }
          &:before {
            content: unset;
          }
        }
      }
    }
  }
  .header-search__suggestions-wrapper .header-search__term-result.link {
    @include body-text--small-bold;
    padding: 0;
    margin: 0;
    background: none;
    #{$ldirection}: 59px;
    color: $color-text-grey;
    height: 37px;
    line-height: 37px;
    opacity: 1;
    top: -2px;
    @include breakpoint($bp--largest-up) {
      @include body-text--small-bold;
      color: $color-text-grey;
      height: 37px;
      line-height: 37px;
      top: 2px;
      #{$ldirection}: 37px;
    }
  }
  // typeahead
  .header-search__see-results,
  .header-search__result__link {
    @include body-text--small-bold;
  }
  .header-search__result__description,
  .product__description-short {
    @include body-text--small;
    text-transform: unset;
    padding-top: 10px;
    &.sku-shaded .description-copy {
      display: block;
    }
  }
  .header-search__term-result {
    @include body-text--small;
    display: flex;
    justify-content: space-between;
  }
  .header-search__see-results {
    height: auto;
  }
  .header-search__results {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    @include breakpoint($bp--medium-up) {
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
    }
    @include breakpoint($bp--largest-up) {
      display: table;
      width: 85%;
    }
    .header-search__result {
      &.first {
        @include breakpoint($bp--medium-up) {
          padding-#{$rdirection}: 10px;
        }
      }
      &.last {
        @include breakpoint($bp--medium-up) {
          padding-#{$ldirection}: 10px;
        }
      }
    }
    .header-search__result:nth-of-type(4n + 2),
    .header-search__result:nth-of-type(4n + 3) {
      @include breakpoint($bp--medium-up) {
        padding: 0 5px;
      }
    }
    .header-search__result:nth-of-type(2n + 1) {
      @include breakpoint($bp--medium-up) {
        margin: 0;
      }
    }
  }
  .header-search__result__image {
    display: block;
    width: 100%;
  }
  .header-search__form > .header-search__form--content {
    .header-search__result {
      margin-bottom: 20px;
      width: calc(50% - 10px);
      @include breakpoint($bp--medium-up) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .header-search__suggestions > .header-search__term-result {
    @include body-text--small-bold;
    padding: 0;
    margin: 0;
    background: none;
    #{$ldirection}: 59px;
    color: $color-text-grey;
    height: 37px;
    line-height: 37px;
    opacity: 1;
    top: -2px;
    @include breakpoint($bp--largest-up) {
      @include body-text--small-bold;
      color: $color-text-grey;
      height: 37px;
      line-height: 37px;
      top: auto;
    }
  }
  &.header-search__result {
    @include breakpoint($bp--largest-up) {
      display: flex;
      flex-wrap: wrap;
    }
    .header-search__result__body {
      align-self: center;
      margin: 0 10px;
      text-align: center;
      @include breakpoint($bp--largest-up) {
        width: 100%;
      }
    }
  }
  .header-search__term-result-metadata--initial {
    @include body-text--small-bold;
    .header-search__result__title {
      @include body-text--small-bold;
      color: $color--black;
      margin-bottom: 24px;
    }
    .header-search__result__link {
      color: $color-text-grey;
      text-transform: lowercase;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  .header-search__form .header-search__close {
    top: 20px;
    width: 12px;
    height: 12px;
    @include breakpoint($bp--medium-up) {
      display: flex !important;
    }
    @include breakpoint($bp--xlarge-up) {
      display: none !important;
    }
  }
}

// Results page
.search-results__results {
  .cr21-refresh {
    h3.product__name {
      @include body-text--small-bold;
    }
    .product__mini-detail {
      @include body-text--small;
    }
    .product--single-not-orderable.prod_inv_status-7 .fade-non-shoppable-product .sold_out_btn,
    .product--sku-product.prod_inv_status-3
      .product__footer
      .product__inventory-status
      .coming-soon,
    .product--teaser.prod_inv_status-3
      .product__footer
      .product__inventory-status
      li.coming-soon-text {
      @include cta-primary-filled--disabled;
    }
    .product__inv-status-item.sold-out {
      background-color: transparent;
    }
    // Hide empty spp link on sku result
    .product__sku-details .product__link-custom {
      display: none;
    }
    // shop shades link
    .product__link-to-spp {
      @include cta-primary-filled;
      display: none;
    }
    .product--shaded.product--teaser .product__link-to-spp {
      display: inline-block;
    }
    .product--teaser .product__footer {
      min-height: 50px;
    }
    // add to bag displays
    .product__add-to-bag {
      @include cta-primary-filled;
    }
    .product--shaded.product--teaser .product__add-to-bag,
    .product--shaded.product--teaser.product--coming-soon .product__link-to-spp,
    .product--coming-soon .product__add-to-bag {
      display: none !important;
    }
    .product--teaser--mini.product--sku-product .product__add-to-bag & {
      display: inline-block;
    }
  }
}
// results page and filters
.cr21-refresh {
  &.page-search__field {
    @include title-03;
    color: $color--black;
    @include breakpoint($bp--largest-up) {
      @include title-03;
      color: $color--black;
    }
  }
}
.search-results {
  position: relative;
}
// filter toggle
.search-filter__trigger {
  @include body-text--small-bold;
  @include breakpoint($bp--large-up) {
    top: 0;
    z-index: 1001;
    margin-top: -75px;
  }
}
// filters section
.search-filter__container {
  @include breakpoint($bp--large-up) {
    background: $color--black--transparent;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 1000;
  }
}
.search-results__filters {
  @include breakpoint($bp--large-up) {
    background: $color--white;
    padding-bottom: 50px;
  }
}
.search-dimension {
  @include breakpoint($bp--large-up) {
    background: $color--white;
    border: none;
    color: $color--black;
  }
}
.search-dimension__header {
  @include body-text--small-bold;
}
.search-dimension a {
  @include body-text--small-bold;
  text-transform: none;
}
