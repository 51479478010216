@import '../../theme-bootstrap';

// .cr21-refresh class is added programmatically to site_header_formatter_v1
// This is where we override styles for the nav
// @todo - pretty much all the bg = white stuff will need to change when we add the transparent nav template,
// but it is good to declare those specifically so we can add the specificity we need later.

.slick-next,
.slick-prev {
  z-index: 1;
}

.cr21-refresh {
  .site-header-formatter {
    &__header {
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 0;
      width: auto;
      #{$rdirection}: 0;
      @include breakpoint($bp--medium-up) {
        padding: 0 20px 0;
        bottom: 4px;
      }
      @include breakpoint($bp--largest-up) {
        padding: 2px 0 2px;
        position: static;
      }
      .viewcart & {
        #{$ldirection}: 0;
      }
      // Override default, mobile / pc toggle happens on $bp--largest-up for gnav
      .mobile-hidden {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: block;
        }
      }
      // Override default, mobile / pc toggle happens on $bp--largest-up for gnav
      .pc-hidden {
        display: block;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
      }
    }
    &__content-block-sitewide-banner {
      .site-header-formatter--sticky & {
        display: none;
      }
    }
    &__inner {
      background-color: $color--white;
    }
    &__top {
      border-bottom: none;
      margin: 12px 20px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .social-shoppe-enabled & {
        display: none;
      }
    }
    &__bar {
      background-color: $color--white;
      margin: 0 22px;
      align-items: flex-start;
      @include breakpoint($bp--medium-up) {
        max-width: 1441px;
        margin: 0 auto;
      }
      .viewcart & {
        min-height: auto;
        margin-top: -55px;
        @include breakpoint($bp--medium-up) {
          min-height: 57px;
          margin: 0 auto;
        }
      }
    }
    &__utilities-content {
      z-index: 999;
    }
    &__logo {
      #{$ldirection}: 0;
      transform: unset;
      position: relative;
      width: 100px;
      margin: 0;
      width: 127px;
      height: 57px;
      order: 2;
      top: -3px;
      transition: width 2s, height 2s;
      @include breakpoint($bp--medium-up) {
        top: 4px;
        #{$ldirection}: 0;
        width: 138px;
        height: 15px;
        order: initial;
      }
      @include breakpoint($bp--large-up) {
        top: 3px;
        width: 165px;
        height: 18px;
      }
      @include breakpoint($bp--xxlarge-up) {
        width: 211px;
        height: 23px;
      }
      .checkout &,
      .viewcart & {
        #{$ldirection}: 30%;
        z-index: 999;
        @include breakpoint($bp--largest-up) {
          #{$ldirection}: 0;
          transform: unset;
          position: relative;
        }
      }
      svg {
        fill: $color--black;
      }
    }
    &__account {
      display: block;
    }
    &__checkout-mobile-back {
      display: none;
      .checkout &,
      .viewcart & {
        display: flex;
        align-items: center;
        padding-#{$rdirection}: 20px;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
      }
      &-icon {
        fill: $color--black;
      }
    }
    &__sections-main-links {
      margin-top: 66px;
      display: flex;
      flex-wrap: wrap;
      @include breakpoint($bp--largest-up) {
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 12px 0 10px;
        margin-top: 0;
      }
    }
    &__mobile-menu-icon {
      fill: $color--white;
      height: 20px;
      width: 20px;
    }
    &__sections {
      width: 100%;
      @include breakpoint($bp--largest-up) {
        padding: 0 20px;
        height: 100%;
      }
      @include breakpoint($bp--large-up) {
        padding: 0;
      }
      .site-header-formatter--close-icon {
        top: 20px;
        width: 16px;
        height: 16px;
        svg {
          fill: $color--white;
          @include breakpoint($bp--largest-up) {
            fill: $color--black;
          }
        }
      }
    }
    &__locator {
      &-link-icon {
        height: 17px;
        width: 17px;
      }
    }
    &__offers {
      background-color: $color--black;
      border-radius: 25px;
      height: 30px;
      order: 1;
      transition: width 1s;
      @include breakpoint($bp--medium-up) {
        margin-#{$rdirection}: -30px;
        order: initial;
        flex: 1;
        margin: 0 20px 0;
      }
      @include breakpoint($bp--xlarge-up) {
        margin-#{$rdirection}: 0;
        margin: 0 20px 0;
      }
      .header-offers-banner-item__offer-text {
        white-space: nowrap;
      }
    }
  }
}

// extra specificity needed in these cases
.cr21-refresh.site-header-formatter {
  min-height: 89px;
  @include breakpoint($bp--largest-up) {
    min-height: 107px;
  }
  .social-shoppe-enabled & {
    height: $social-shopping-bar-height + $navbar-height;
    @include breakpoint($bp--largest-up) {
      height: 122px;
    }
  }
  // Mobile menu trigger (Hamburger).
  .site-header-formatter__menu-icon {
    position: absolute;
    float: none;
    #{$rdirection}: 0;
    margin-#{$rdirection}: 0;
    margin-#{$ldirection}: 0;
    margin-top: 0;
    top: 15px;
    svg {
      fill: $color--black;
    }
  }
  // mobile menu expanded state.
  // we hope specificity wars rule here.
  .site-header-formatter__mobile-trigger:checked {
    ~ .site-header-formatter__sections {
      background-color: $color--black;
      color: $color--white;
      padding-bottom: 80px;
    }
  }
  .site-header-formatter__utilities {
    background-color: transparent;
    width: 150px;
    margin-#{$rdirection}: 40px;
    position: relative;
    order: 2;
    @include breakpoint($bp--xxsmall-up) {
      margin-#{$rdirection}: 45px;
    }
    @include breakpoint($bp--medium-up) {
      width: auto;
      order: initial;
      margin-#{$rdirection}: 40px;
      margin-#{$ldirection}: -3px;
      width: 100px;
    }
    @include breakpoint($bp--xlarge-up) {
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
    }
    @include breakpoint($bp--largest-up) {
      margin: 0;
      width: 215px;
    }
    .checkout &,
    .viewcart & {
      background-color: transparent;
    }
  }
  .site-header-formatter__utilities--loyalty {
    .social-shoppe-enabled & {
      display: none;
    }
    @include breakpoint($bp--medium-up) {
      display: none;
    }
    @include breakpoint($bp--large-up) {
      text-align: $rdirection;
      padding-#{$rdirection}: 0;
      padding-#{$ldirection}: 0;
      #{$ldirection}: auto;
      #{$rdirection}: 15%;
      height: 30px;
      line-height: 30px;
    }

    @include breakpoint($bp--xlarge-up) {
      display: block;
      #{$rdirection}: 25%;
    }
    @include breakpoint($bp--xxlarge-up) {
      #{$rdirection}: 21%;
    }
    @include breakpoint($bp--xxlarger-up) {
      #{$rdirection}: 18%;
    }
    .site-header-formatter__utilities--loyalty-link {
      @include body-text--small-bold;
      color: $color--white;
      text-transform: uppercase;
    }
  }
  .site-header-formatter {
    &__links-link {
      @include body-text--small-bold;
    }
    &__links--loyalty,
    &__links--account {
      display: flex;
      justify-content: flex-end;
      background: transparent;
    }
    &__links--account {
      height: 72px;
      align-items: center;
      .site-header-formatter__links-link {
        height: 16px;
        width: fit-content;
      }
    }
    &__links--loyalty {
      height: 52px;
      border-top: 1px solid $color-cta-grey;
      margin: 0 20px;
      .social-shoppe-enabled & {
        display: none;
      }
      .site-header-formatter__links-link {
        @include body-text--small-bold;
        height: 24px;
        margin: 0;
        padding: 5px 15px;
        position: static;
        color: $color--white;
        background: $color--black;
        box-shadow: none;
        border: 0;
        border-radius: 50px;
        u {
          text-decoration: none;
        }
      }
    }
    &__utilities {
      z-index: 2;
      .active-gnav.search-active & {
        z-index: unset;
      }
    }
    &__sections-main-links {
      z-index: 2;
      .active-gnav.search-active & {
        z-index: unset;
      }
    }
    &__mobile-menu-header-label {
      // this could be removed once we get a left arrow svg
      transform: rotate(90deg);
      padding-#{$ldirection}: 0;
      padding-bottom: 20px;
      @if $text-direction == rtl {
        transform: rotate(-90deg);
      }
      else {
        transform: rotate(90deg);
      }
    }
    &__links-container {
      background: $color--white;
      position: fixed;
      z-index: 2;
      bottom: 0;
    }
    &__links-link-label {
      &,
      &:hover,
      &:active,
      &:focus,
      &:visited {
        color: $color--black;
        padding: 0;
      }
      span {
        text-decoration: underline;
      }
    }
  }

  // Hide the search field that is exposed in the menu.
  // For the refresh we only want the field triggered by search icon.
  .site-header-formatter__search {
    @include breakpoint($bp--largest-up) {
      margin-#{$rdirection}: -7px;
    }
    @include breakpoint($bp--xlarge-up) {
      margin-top: 0;
    }
    @include breakpoint($bp--xxlarge-up) {
      // 1280px
      position: relative;
      bottom: auto;
      margin-#{$rdirection}: 0;
      align-items: normal;
      height: 50px;
      top: -12px;
    }
    .header-search {
      padding: 0;
      @include breakpoint($bp--medium-up) {
        margin-top: -10px;
      }
      @include breakpoint($bp--large-up) {
        margin-top: -13px;
      }
      @include breakpoint($bp--largest-up) {
        margin-top: -4px;
      }
      @include breakpoint($bp--xxlarge-up) {
        margin-top: 0;
      }
    }
  }
  // section formatter
  .gnav-section-formatter {
    @include body-text;
    @include breakpoint($bp--largest-up) {
      font-size: 12px;
    }
    &__gnav-links-wrapper {
      .gnav-links.only-gnav-link,
      .gnav-links:only-of-type {
        .gnav-links__link--viewall {
          border-top: none;
          position: relative;
        }
        .gnav-links__content {
          padding: 0;
        }
      }
      .gnav-tout {
        display: block;
        &__link {
          a {
            text-transform: uppercase;
          }
        }
      }
      > div {
        margin: 0 $gnav-mobile-hpad--outer;
        padding: $gnav-mobile-hpad--outer 0;
        border-top: 1px solid $color-text-grey;
        @include breakpoint($bp--largest-up) {
          @include swap_direction(margin, 0 40px 0 0);
          padding: 0;
          border-top: none;
        }
        &:first-of-type {
          border-top: none;
        }
        &:last-of-type {
          border-bottom: 1px solid $color-text-grey;
          @include breakpoint($bp--largest-up) {
            border-bottom: none;
            margin-#{$rdirection}: 0;
          }
        }
      }
    }
    &__gnav-links-wrapper {
      .gnav-links.only-gnav-link .gnav-links__link,
      .gnav-links:only-of-type .gnav-links__link,
      .gnav-links__link {
        @include body-text;
        box-shadow: none;
        height: auto;
        padding-bottom: 16px;
        margin: 0 0 16px;
        @include breakpoint($bp--largest-up) {
          @include legal-text;
          padding-bottom: 0;
        }
        a,
        a:visited,
        a:hover {
          @include breakpoint($bp--largest-up) {
            color: $color-text-grey;
            text-transform: none;
          }
        }
      }
      .gnav-links__link--break {
        padding-bottom: 0;
      }
    }
  }

  // sections
  .header-nav-section {
    &__section {
      @include body-text--small-bold;
      background-color: $color--black;
      color: $color--white;
      margin-top: 0;
      width: 50%;
      padding-bottom: 24px;
      @include breakpoint($bp--largest-up) {
        @include body-text--small-bold;
        background-color: $color--white;
        color: $color--black;
        width: inherit;
        padding: 9px 14px 4px;
      }
      @include breakpoint($bp--xlarge-up $bp--xlarge-down) {
        padding: 9px 22px 4px;
      }
      a {
        &,
        &:visited,
        &:active,
        &:focus,
        &:hover {
          @include body-text--small-bold;
          color: $color--white;
          font-size: 14px;
          line-height: 16px;
          border-bottom: none;
          @include breakpoint($bp--largest-up) {
            @include body-text--small-bold;
            border-bottom-color: transparent;
            color: $color--black;
            font-size: 12px;
          }
        }
      }
      .gnav-tout__link a,
      a.gnav-links__viewall-link {
        &,
        &:visited,
        &:active,
        &:focus,
        &:hover {
          border-bottom-color: $color--gray--light;
          @include breakpoint($bp--largest-up) {
            border-bottom-color: transparent;
            text-decoration: underline;
            text-transform: uppercase;
          }
        }
      }
    }
    // ouch
    &__trigger:checked {
      ~ .header-nav-section__section
        .header-nav-section__section-row-link
        .header-nav-section__section-label {
        @include body-text--small-bold;
        color: $color--white;
        @include breakpoint($bp--largest-up) {
          @include body-text--small-bold;
          color: $color--black;
        }
      }
      + .header-nav-section__section {
        background-color: $color--black;
        color: $color--white;
        &[aria-expanded='true'] {
          margin-top: -68px;
          padding-top: 28px;
          width: 100%;
          font-size: 14px;
          @include breakpoint($bp--largest-up) {
            margin-top: 0;
            padding-top: 9px;
            width: auto;
            font-size: 12px;
          }
        }
        @include breakpoint($bp--largest-up) {
          background-color: $color--white;
          color: $color--black;
        }
      }
      ~ .site-header-formatter__mobile-menu-header {
        @include body-text--small-bold;
        background-color: transparent;
        color: $color--white;
        line-height: 56px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: absolute;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
      }
    }
    // Show the overlay when a Main Section is selected on PC.
    &__trigger:checked + .header-nav-section__section + label.site-header-formatter-overlay {
      @include breakpoint($bp--largest-up) {
        top: $gnav-height + 12px;
      }
      .social-shoppe-enabled & {
        @include breakpoint($bp--largest-up) {
          top: $social-shopping-bar-height-large + $navbar-height;
        }
      }
    }
    &__section-content {
      margin-top: 20px;
      background-color: $color--black;
      @include breakpoint($bp--largest-up) {
        background-color: $color--white;
        margin-top: 0;
      }
    }
    &__section-label {
      &,
      &:visited,
      &:active,
      &:focus,
      &:hover {
        @include body-text--small-bold;
        color: $color--white;
        @include breakpoint($bp--largest-up) {
          @include body-text--small-bold;
          color: $color--black;
        }
      }
    }
    .header-nav-section__section-label {
      @include body-text--small-bold;
      color: $color--white;
      @include breakpoint($bp--largest-up) {
        @include body-text--small-bold;
        color: $color--black;
      }
      &:visited,
      &:active,
      &:focus,
      &:hover {
        @include body-text--small-bold;
        color: $color--white;
        @include breakpoint($bp--largest-up) {
          @include body-text--small-bold;
          color: $color--black;
        }
      }
    }
    .header-nav-section__mobile-menu-icon {
      fill: $color--white;
      .ada-enabled &,
      .js.ada-enabled &,
      .no-js & {
        @include breakpoint($bp--largest-up) {
          fill: $color--black;
        }
      }
    }
  }
}

// Subnav area - links
.cr21-refresh {
  &.header-nav-section__section-row-link {
    border-bottom: none;
    color: $color--white;
    margin: 0 20px;
    line-height: initial;
    height: auto;
    .header-nav-section__section-label {
      font-size: 14px;
      @include breakpoint($bp--largest-up) {
        font-size: 12px;
      }
    }
    @include breakpoint($bp--largest-up) {
      box-shadow: none;
      margin: 0;
      border-bottom: none;
    }
    .picker-radio.picker {
      display: none;
    }
  }
  .no-js-element + .picker {
    display: none;
  }
  .site-bag + .picker-checkbox {
    display: none;
  }
  .site-my-mac__link .picker-handle {
    display: none;
  }
  .header-nav-section__section + .picker-radio.picker {
    display: none;
  }
  .header-nav-section__section-content {
    .picker-radio,
    .picker-checkbox {
      display: none;
    }
  }
  .gnav-links {
    background-color: $color--black;
    color: $color--white;
    @include breakpoint($bp--largest-up) {
      background-color: $color--white;
      color: $color--black;
    }
    &:first-of-type:not(:only-of-type) {
      .gnav-links__header {
        margin-top: -24px;
      }
    }
    .gnav-links__header {
      @include breakpoint($bp--large-up) {
        margin-top: -24px;
      }
    }
    &__header-label {
      @include body-text--small-bold;
      margin: 0;
      box-shadow: none;
      line-height: 3.4;
      @include breakpoint($bp--largest-up) {
        line-height: 1.16667;
      }
    }
    &__header ~ .gnav-links__content {
      background-color: $color--black;
      padding: 0;
      margin-top: 0;
      @include breakpoint($bp--largest-up) {
        background-color: $color--white;
      }
    }
    .gnav-links__content {
      display: flex;
      flex-direction: column;
      @include breakpoint($bp--largest-up) {
        display: flex;
      }
    }
    .gnav-link {
      @include body-text;
      text-transform: uppercase;
      @include breakpoint($bp--largest-up) {
        @include legal-text;
      }
      &:visited,
      &:active,
      &:focus,
      &:hover {
        @include body-text;
        text-transform: none;
        @include breakpoint($bp--largest-up) {
          @include legal-text;
          text-transform: uppercase;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &__mobile-icon {
      fill: $color--white;
      display: none;
    }
  }
}

// Subnav area - touts
.cr21-refresh {
  .gnav-tout {
    &__headline {
      @include body-text--small-bold;
      @include swap_direction(padding, 3px 9px 2px 8px);
      color: $color--white;
      border: 1px solid $color--white;
      border-radius: 100px;
      width: auto;
      max-width: 57%;
      margin-top: 0;
      @include breakpoint($bp--largest-up) {
        @include body-text--small-bold;
        color: $color--black;
        padding: 0;
        margin: 15px 0;
        max-width: initial;
        border: none;
      }
    }
    &__inner-content {
      @include body-text--small;
      color: $color--white;
      @include breakpoint($bp--largest-up) {
        color: $color--black;
      }
    }
    &__image {
      padding-#{$rdirection}: 20px;
      @include breakpoint($bp--largest-up) {
        padding-#{$rdirection}: 0;
      }
    }
  }
}

// Submenu area - bestsellers nav
.submenu {
  height: 68px;
  &__header {
    display: none;
  }
  &__nav {
    width: 100%;
    ul {
      height: 68px;
      display: flex;
      justify-content: center;
      li {
        border: none;
        width: 100%;
        white-space: nowrap;
        max-width: 250px;
        &.active {
          border-bottom: 1px solid $color--black;
        }
        &[class*='menu-mlid'] {
          &.active-trail {
            border-bottom: 1px solid $color--black;
          }
        }
        a {
          @include body-text--small-bold;
          color: $color-text-grey !important;
          text-align: center;
          width: 100%;
          border: none;
          margin-top: 25px;
          &:hover,
          &.active {
            border: none;
          }
        }
        &.active {
          a {
            color: $color--black !important;
            font-weight: bold;
          }
        }
      }
    }
  }
  // increase specificity for submenu items line-height
  .submenu__nav {
    ul {
      li {
        a {
          line-height: 14px;
        }
      }
    }
  }
}
.artistry-artists-page__filter,
.artistry-videos__filter {
  width: auto;
  z-index: 2;
  @include breakpoint($bp--xlarge-up) {
    #{$rdirection}: 20px;
  }
  @include breakpoint($bp--xxxlargest-up) {
    #{$rdirection}: 190px;
  }
  &:before {
    content: none;
  }
  .sec-nav {
    &__sections {
      border: 1px solid $color--gray--lighter;
      border-radius: 30px;
      margin-top: 8px;
      max-width: none;
      min-width: 95px;
      &:hover {
        border: 1px solid $color--black;
        .sec-nav__link {
          &:after {
            background: url('#{$base-theme-path}svg-icons/src/icon--chevron-up.svg') no-repeat 20px
              50%;
            background-size: 12px;
            background-position: center;
          }
        }
      }
    }
    &__item {
      border: none;
      min-width: auto;
      width: auto;
      padding-bottom: 18px;
      height: 50px;
      min-width: 95px;
    }
    &__link,
    &__sub-item,
    &__sub-link {
      @include body-text--small-bold;
    }
    &__sub-nav {
      color: $color--black;
      border: 1px solid $color--black;
      border-radius: 20px;
      top: 52px;
    }
    &__link {
      &:after {
        content: '';
        background: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg') no-repeat 20px
          50%;
        background-size: 12px;
        background-position: center;
        position: absolute;
        width: 25px;
        height: 25px;
        font-size: 22px;
        color: black;
        right: 5px;
        top: 25%;
      }
      &:before {
        content: none;
      }
    }
    &__sub-link {
      text-transform: capitalize;
      color: $color-text-grey;
      &:hover {
        color: $color--black;
      }
    }
  }
}
