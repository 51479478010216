@import '../../theme-bootstrap';

@keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(0%);
  }
  100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
  }
}

// .cr21-refresh class is added programmatically to site_header_formatter_v1
// This is where we override styles for the offers banner

.cr21-refresh {
  .header-offers-banner-formatter {
    background-color: transparent;
    height: 30px;
    display: flex;
    &__close {
      background-color: $color--white;
      border-radius: 16px;
      width: 16px;
      height: 16px;
      // @todo does this ever display?
      .icon {
        fill: $color--black;
      }
    }
    .header-offers-banner-item {
      padding: 0 1rem;
      &__link {
        @include body-text--small-bold;
        font-size: 13px;
        line-height: get-line-height(13px, 13px);
        margin-#{$ldirection}: 5px;
        margin-top: 1px;
        align-self: center;
      }
      &.item-active {
        display: flex;
        justify-content: center;
        @include breakpoint($bp--medium-up) {
          justify-content: flex-start;
        }

      }
    }
    // only do this when loyalty is enabled and text does not fit the container
    &__carousel {
      @include breakpoint($bp--medium-up) {
        margin: 0;
        position: relative;
      }
      &:before {
        content: '';
        width: 20px;
        height: 30px;
        position: absolute;
        #{$ldirection}: 0;
        top: 0;
        display: block;
        z-index: 100000;
        background: linear-gradient(to right, $color--black--opacity-9 0%, $color--black--opacity-10 100%);
      }
      &:after {
        content: '';
        width: 20px;
        height: 30px;
        position: absolute;
        #{$rdirection}: 0;
        top: 0;
        display: block;
        z-index: 100000;
        background: linear-gradient(to left, $color--black--opacity-9 0%, $color--black--opacity-10 100%);
      }
      &:hover,
      &:active {
        .header-offers-banner-item {
          .offer-text-scroll {
            animation-play-state: paused;
          }
        }
      }
      .header-offers-banner-item {
        .offer-text-scroll {
          @include breakpoint($bp--medium-up) {
            transform: translateY(0%);
            animation: scroll-left 30s infinite linear;
            top: 0;
            animation-delay: 2s;
            margin-right: 10%;
            overflow: visible;
          }
        }
        &.offer-center-aligned {
          justify-content: center;
        }
      }
    }
    .gnav-transparent--light:not(.site-header-formatter--sticky) & {
      .header-offers-banner-formatter__carousel {
        &:before {
          background: linear-gradient(to right, $color--white--opacity-9 0%, $color--white--opacity-1 100%);
        }
        &:after {
          background: linear-gradient(to left, $color--white--opacity-9 0%, $color--white--opacity-1 100%);
        }
      }
    }
  }
  .header-offers-banner-item__offer-text {
    @include body-text--small-bold;
    line-height: 32px;
    * {
      @include body-text--small-bold;
    }
    p {
      line-height: 32px;
      strong {
        font-size: 13px;
        line-height: get-line-height(13px, 13px);
      }
    }
  }
}
