@import '../../theme-bootstrap';

// .cr21-refresh class is added programmatically to site_header_formatter_v1
// This is where we override styles for the bag within header nav

.cr21-refresh {
  .site-bag {
    display: flex;
    justify-content: center;
    height: auto;
    @include breakpoint($bp--largest-up) {
      height: 32px;
      background-color: transparent;
      margin-top: 3px;
    }
    &__link {
      position: relative;
      margin-#{$ldirection}: 5px;
      margin-top: 14px;
      display: inline-block;
      width: auto;
      @include breakpoint($bp--medium-up) {
        margin-top: 3px;
        width: 35px;
      }
      @include breakpoint($bp--largest-up) {
        margin-top: 0;
      }
      @include breakpoint($bp--xlarge-up) {
        margin-top: -5px;
      }
      svg {
        width: 22px;
        height: 22px;
        fill: $color--black;
        .gnav-transparent--light:not(.site-header-formatter--sticky) & {
          fill: $color--white;
        }
      }
      &-icon {
        background: url('#{$base-theme-path}svg-icons/src/icon--bag.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        &:before {
          content: unset;
        }
        @include breakpoint($bp--medium-up) {
          line-height: 20px;
          width: 22px;
          height: 22px;
        }
      }
      &-count {
        color: $color--black;
        height: auto;
        line-height: 20px;
        top: 5px;
        #{$ldirection}: 0;
        width: 20px;
        transform: none;
        width: 20px;
        .gnav-transparent--light:not(.site-header-formatter--sticky) & {
          fill: $color--white;
        }
        .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
          fill: $color--black;
        }
        @include breakpoint($bp--medium-up) {
          line-height: get-line-height(10px, 30px);
          width: 22px;
          top: 0;
          #{$ldirection}: 17%;
        }
        @include breakpoint($bp--largest-up) {
          line-height: get-line-height(10px, 41px);
        }
      }
    }
  }
  .active,
  .gnav-util-trigger:checked ~ .site-bag__link {
    .site-bag__link-count {
      color: $color--black;
    }
  }
  .site-header-formatter__sections {
    .site-bag {
      position: fixed;
      top: 15px;
      height: 26px;
      #{$rdirection}: 60px;
      z-index: 2;
      background: transparent;
      @include breakpoint($bp--largest-up) {
        display: none;
      }
      &.active {
        background: transparent;
        width: 100%;
        height: 100%;
      }
      &__link {
        width: 24px;
        height: 24px;
        margin-top: 2px;
        &-label {
          width: 22px;
          height: 22px;
          display: block;
          svg {
            height: 100%;
            width: 100%;
            fill: $color--white;
            position: absolute;
            #{$ldirection}: 0;
          }
        }
      }
    }
    .site-bag__link-count {
      color: $color--white;
      font-size: 10px;
      line-height: 1;
      text-align: center;
      top: 50%;
      width: 22px;
    }
  }
}
