@import '../../theme-bootstrap';

.cr21-refresh {
  &.mac-select-loyalty-footer-formatter {
    background: $color--black;
    li {
      padding: 0 4px;
      @include body-text--small-bold;
      font-size: 9px;
    }
  }
  .at-h6__sitefooter {
    @include body-text--small-bold;
  }
}

.footer-menu--pc {
  .cr21-refresh {
    li {
      margin-bottom: 10px;
    }
    a {
      @include body-text--small;
      color: $color-text-grey;
      padding: 0;
      text-decoration: none;
      text-transform: uppercase;
      &:visited {
        color: $color-text-grey;
      }
      &:hover {
        text-decoration: underline;
        color: $color--white;
      }
    }
  }
}

.site-footer--top {
  background: $color--black;
  @include breakpoint($bp--largest-up) {
    background: $color--black;
  }
  .cr21-refresh {
    ul li {
      @include body-text--small;
      text-transform: uppercase;
      a {
        text-transform: uppercase;
      }
    }
  }
  .social-links {
    .at-h6__sitefooter {
      @include body-text--small-bold;
    }
  }
  .block--footer-extra {
    .language-picker a {
      @include body-text--small;
      color: $color-text-grey;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.site-footer--bottom {
  @include body-text--small;
}
